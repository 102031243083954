import axios from 'axios';

//const API_URL = 'https://crop-layer.azurewebsites.net/api/';
const API_URL = 'http://crop-layer-api.production.cmosteknoloji.com/api/';

class AuthService {
    login(user) {
        return axios
            .post(API_URL + 'Auth/Login', {
                email: user.username,
                password: user.password
            })
            .then(response => {
                if (response.data.token) {
                    localStorage.setItem('user', JSON.stringify(response.data.token));
                }

                return response.data;
            });
    }

    logout() {
        localStorage.removeItem('user');
    }
}

export default new AuthService();