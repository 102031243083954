import Vue from 'vue'
import Router from 'vue-router'
import Login from './components/Login.vue'
import Crop from "./components/Crop.vue"

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path:'/',
            name:'Login',
            component: Login
        },
        {
            path:'/removebg',
            name:'Crop',
            component: Crop
        }
    ]
})
