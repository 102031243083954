<template>
  <div
    style="display: flex;justify-content: center; align-items:center; flex-direction:column"
  >
    <b-toast
      id="example-toast"
      title="HATA"
      variant="danger"
      static
      no-auto-hide
    >
      Servis tarafında bir hata oluştu. Görselin boyutunun çok büyük oluşundan
      kaynaklanıyor olabilir!
    </b-toast>

    <div style="display:flex;width:700px" class="mb-3">
      <label for="input-small">foreground_threshold:</label>
      <b-input
        id="input-small"
        size="sm"
        v-model="alpha_matting_foreground_threshold"
        type="number"
        max="350"
        class="mr-4"
      ></b-input>

      <label for="input-small">background_threshold:</label>

      <b-input
        v-model="alpha_matting_background_threshold"
        id="input-default"
        size="sm"
        type="number"
        max="100"
        class="mr-4"
      ></b-input>

      <label for="input-small">erode_structure_size:</label>

      <b-input
        id="input-large"
        size="sm"
        v-model="alpha_matting_erode_structure_size"
        type="number"
        max="100"
      ></b-input>
    </div>
    <div>
      <b-form-file
        type="file"
        @change="onFileSelected"
        accept="image/*"
        v-model="selectedImage"
        :state="Boolean(selectedImage)"
        placeholder="Choose a file"
      ></b-form-file>
    </div>
    <div
      style="display : flex; justify-content: center; justify-items: center;"
    >
      <b-button
        id="upload"
        @click="handleImage"
        variant="primary"
        style="visibility: visible;margin-top: 10px; margin-right: 25px"
        disabled
      >
        Upload
      </b-button>
      <b-button
        id="loading"
        variant="primary"
        disabled
        style="visibility: visible; margin-right: 25px; margin-top: 10px"
        hidden
      >
        <b-spinner small type="grow"></b-spinner>
        Loading...
      </b-button>

      <b-button
        @click="downloadWithAxios"
        id="download"
        style="margin-top: 10px;margin-left: 25px"
        variant="success"
        disabled
      >
        Download
      </b-button>
    </div>

    <div
      style="margin-top: 10px;display : flex; justify-content: center; justify-items: center; "
    >
      <!-- <img style="max-width: 500px !important;margin-right: 25px"
             :src="'data:image/png;base64,' + selectedImage" alt=""> -->

      <cropper
        style="max-width: 500px ;margin-right: 25px"
        check-orientation
        :src="img"
        :max-height="maxHeight"
        :max-width="maxWidth"
        :min-height="minHeight"
        :min-width="minWidth"
        :size-restrictions-algorithm="percentsRestriction"
        :adjust-stencil="true"
        @change="onCrop"
      />

      <img
        style="max-width: 500px !important; margin-left: 25px"
        :src="'data:image/png;base64,' + responseImage"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

let defaultImg =
  "iVBORw0KGgoAAAANSUhEUgAABHQAAAMbAQMAAAAWx1D0AAAABlBMVEUxNkAAAADfNHjcAAAAAnRSTlMnAKxWf10AAAKESURBVHja7NKhFYNAFADBLyNTQtIJj8oQ" +
  "FI7lKrgVY1avmPne/8+ZyW+2L6yZ/QvvzGxfWHLN9oUlB88888wzzzzzzDPPPPPMM888B8IzzzzzzDPPPPPMM88888wzz4nwzDPPPPPMM88888wzzzzzzHMgPPPMM88888wzzzzzz" +
  "DPPPPNcCM8888wzzzzzzDPPPPPMM888F8IzzzzzzDPPPPPMM88888wzz4XwzDPPPPPMM88888wzzzzzzHMiPPPMM88888wzzzzzzDPPPPMcCM8888wzzzzzzDPPPPPMM888F8Izzzzzz" +
  "DPPPPPMM88888wzz4XwzDPPPPPMM88888wzzzzzzHMhPPPMM88888wzzzzzzDPPPPOcCM8888wzzzzzzDPPPPPMM888B8IzzzzzzDPPPPPMM88888wzz4XwzDPPPPPMM88888wzzzzzz" +
  "HMhPPPMM88888wzzzzzzDPPPPNcCM88P+3SIQEAAACAoP+vvWGgsACfffbZZ5999tlnn3322ecFPvvss88+++yzzz777LPPPvs8wGefffbZZ5999tlnn3322WefD/jss88+++yzzz777LPP" +
  "Pvvs8wGfffbZZ5999tlnn3322WeffT7gs88+++yzzz777LPPPvvss88LfPbZZ5999tlnn3322WefffZ5gM8+++yzzz777LPPPvvss88+H/DZZ5999tlnn3322WefffbZ5wM+++yzzz777LPP" +
  "Pvvss88++3zAZ5999tlnn3322WefffbZZ58X+Oyzzz777LPPPvvss88+++zzAJ999tlnn3322WefffbZZ599PuCzzz777LPPPvvss88+++yzzwd89tlnn32efg7ejXQHnPVErgAAAABJRU5ErkJggg==";
export default {
  name: "Crop",
  components: {
    Cropper,
  },
  data() {
    return {
      img: "default.jpg",
      responseImage: defaultImg,
      selectedImage: defaultImg,
      imageName: "",
      alpha_matting_foreground_threshold: 240,
      alpha_matting_background_threshold: 10,
      alpha_matting_erode_structure_size: 15,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    }
  },
  methods: {
    onFileSelected(e) {
      this.$bvToast.hide("example-toast");
      document.getElementById("download").disabled = true;
      this.selectedImage = e.target.files[0];
      this.imageName = e.target.files[0].name.split(".")[0];
      this.createBase64Image(this.selectedImage);
      document.getElementById("upload").disabled = false;
      document.getElementById("upload").style.opacity = "1";
    },

    handleImage() {
      this.$bvToast.hide("example-toast");
      document.getElementById("upload").setAttribute("hidden", "");
      document.getElementById("loading").removeAttribute("hidden");
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.currentUser.token,
        },
      };
      let req = {
        imageBase64: this.selectedImage,
        alpha_matting_foreground_threshold: this
          .alpha_matting_foreground_threshold,
        alpha_matting_background_threshold: this
          .alpha_matting_background_threshold,
        alpha_matting_erode_structure_size: this
          .alpha_matting_erode_structure_size,
      };
      axios
        .post("https://crop-layer.azurewebsites.net/api/User/AddMediaHP", req, config)
        .then((response) => {
          this.responseImage = response.data.imageBase64;
          document.getElementById("upload").removeAttribute("hidden");
          document.getElementById("loading").setAttribute("hidden", "");
          document.getElementById("download").disabled = false;
          document.getElementById("download").style.opacity = "1";
          document.getElementById("upload").disabled = true;
        })
        .catch(() => this.errorOccured());
    },

    errorOccured() {
      this.$bvToast.show("example-toast");
      document.getElementById("upload").removeAttribute("hidden");
      document.getElementById("loading").setAttribute("hidden", "");
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.img = e.target.result;
        //this.selectedImage = e.target.result.split(",")[1];
      };
      reader.readAsDataURL(fileObject);
    },

    forceFileDownload(response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "nobg-" + this.imageName + ".png");
      document.body.appendChild(link);
      link.click();
    },

    downloadWithAxios() {
      axios({
        method: "get",
        url: "data:image/png;base64," + this.responseImage,
        responseType: "arraybuffer",
      })
        .then((response) => {
          this.forceFileDownload(response);
        })
        .catch(() => console.log("error occurred"));
    },
    percentsRestriction({
      minWidth,
      minHeight,
      maxWidth,
      maxHeight,
      imageSize,
    }) {
      return {
        maxWidth: (imageSize.width * (maxWidth || 100)) / 100,
        maxHeight: (imageSize.height * (maxHeight || 100)) / 100,
        minWidth: (imageSize.width * (minWidth || 0)) / 100,
        minHeight: (imageSize.height * (minHeight || 0)) / 100,
      };
    },
    onCrop({ canvas }) {
      this.selectedImage = canvas.toDataURL();
      this.selectedImage = this.selectedImage.split(",")[1];
    },
  },
};
</script>
